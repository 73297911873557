import * as React from 'react'
import { Link, navigate } from "gatsby"
import Axios from "axios"
import { setItem, isLoggedIn } from "../../services/auth"
import SocialLogin from '../../components/socialLogin'


export default function Login() {

  function onLoginSocial(e, loginType){
    //lambda url
    var url = `/.netlify/functions/auth/${loginType}`;
    //var url = `http://localhost:9000/.netlify/functions/auth/${loginType}`;
    var win = typeof window !== `undefined` ? window : null;
    var n = win.open(url, "_self");
    if (n == null) {
      return true;
    }
    return false;
  }

  function onLoginEmail(e) {
    e.preventDefault();
    console.log(e);
    const data = {
      email: e.target.email.value,
      password: e.target.password.value
    };
    
    Axios.post('https://api.gogoblock.io/api/login-email', data)
      .then((result) => {
          console.log(result);
          if(result.data.success){
            setItem("user", JSON.stringify(result.data.user));
            setItem("token", JSON.stringify(result.data.token))
            navigate(`/member/account`)
          }else{

          }
      }).catch((err) => {
          console.error(err);
      });
  };

    return (
        <>
          <div className="min-h-full flex">
              <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                  <div>
                    <img
                      className="h-12 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                      alt="Workflow"
                    />
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
                    <p className="mt-2 text-sm text-gray-600">
                      Or{' '}
                      <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                        start your 14-day free trial
                      </a>
                    </p>
                  </div>
  
                  <div className="mt-8">
                    <div>
                    <SocialLogin />
  
                      <div className="mt-6 relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                          <span className="px-2 bg-white text-gray-500">Or continue with</span>
                        </div>
                      </div>
                    </div>
  
                    <div className="mt-6">
                      <form action="#" method="POST" className="space-y-6" onSubmit={onLoginEmail}>
                        <div>
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email address
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
  
                        <div className="space-y-1">
                          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password
                          </label>
                          <div className="mt-1">
                            <input
                              id="password"
                              name="password"
                              type="password"
                              autoComplete="current-password"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
  
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <input
                              id="remember-me"
                              name="remember-me"
                              type="checkbox"
                              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                              Remember me
                            </label>
                          </div>
  
                          <div className="text-sm">
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                              Forgot your password?
                            </a>
                          </div>
                        </div>
  
                        <div>
                          <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Sign in
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden lg:block relative w-0 flex-1">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                  alt=""
                />
              </div>
            </div>
          </>
    )
  }
  
